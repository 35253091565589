<template>
  <v-container>
    <h1>Account</h1>
    <div v-if="showAlert" :class="alertClass" style="padding:10px">
      {{ alertText }}
    </div>
    <v-row>
      <v-col md="6">
        <h2>User Settings</h2>
        <div class="d-flex align-center">
          <v-text-field v-model="simbriefUsername" label="SimBrief Username"/>
          <v-btn v-on:click="updateAccountSettings(simbriefUsername)" :loading="simbriefUsernameSaving" style="margin:5px;">Save</v-btn>
        </div>
        <h2>Change Password</h2>
        <v-form>
          <v-text-field v-model="currentPassword" label="Current password" type="password" autocomplete="current-password"/>
          <v-text-field v-model="newPassword" label="New Password" type="password" autocomplete="new-password"/>
          <v-btn v-on:click="updatePassword(currentPassword, newPassword)" :loading="updatePasswordSaving">Update</v-btn>
        </v-form>
      </v-col>
      <v-col md="6">
        <v-card>
          <v-card-title>
            Client Tokens
            <v-spacer></v-spacer>
            <v-btn v-on:click="generateToken()" color="success">Generate Token</v-btn>
          </v-card-title>
          <v-data-table :items="tokens" :headers="headers">
            <template #item.time_created="{ item }">
              <span>{{new Date(item['time_created']).toLocaleString()}}</span>
            </template>
            <template #item.actions="{ item }">
              <v-btn color="error" v-on:click="deleteTokenWithPopup(item.token)">
                Delete
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12">
        <v-row>
          <v-col>
            <h2 class="text-center">Live Aircraft Data</h2>
          </v-col>
        </v-row>
        <v-row  v-if="clientData !== null">
          <v-col md="6" cols="12">
            Aircraft: {{clientData['aircraft_title']}}<br>
            Airspeed: {{clientData['airspeed'].toFixed(2)}} Knots<br>
            Airspeed Barber Pole : {{clientData['airspeed_barber_pole']}} Knots<br>
            Ground Speed: {{clientData['ground_speed'].toFixed(2)}} Knots<br>
            Over Ground Alt: {{clientData['altitude_above_ground'].toFixed(2)}} ft<br>
            Alt: {{clientData['altitude'].toFixed(2)}} ft<br>
            Lat: {{clientData['latitude'].toFixed(2)}}<br>
            Long: {{clientData['longitude'].toFixed(2)}}<br>
            Heading Mag: {{radians_to_degrees(clientData['heading_mag']).toFixed(2)}} &#176;<br>
            OnGround: {{clientData['on_ground']}}<br>
          </v-col>
          <v-col md="6" cols="12" style="text-align: right">
            Pitch: {{radians_to_degrees(clientData['pitch'] * -1).toFixed(2)}} &#176;<br>
            Bank: {{radians_to_degrees(clientData['bank']).toFixed(2)}} &#176;<br>
            G-Force: {{clientData['g_force'].toFixed(2)}} G-Force<br>
            VSpeed: {{(clientData['vertical_speed_aircraft'] * 60).toFixed(2)}} ft/min<br>
            Wind Speed: {{clientData['wind_speed'].toFixed(2)}} Knots<br>
            Wind Direction: {{radians_to_degrees(clientData['wind_direction']).toFixed(2)}} &#176;<br>
            Fuel Level: {{clientData['fuel_total'].toFixed(2)}}kg<br>
            Fuel Max kg: {{(clientData['fuel_capacity'] * clientData['fuel_weight_per_gallon']).toFixed(2)}} kg<br>
            Fuel: {{((clientData['fuel_total'] / (clientData['fuel_capacity'] * clientData['fuel_weight_per_gallon'])) * 100).toFixed(2)}}%<br>
          </v-col>
        </v-row>
        <v-row v-else>
          No Client Connected
        </v-row>
      </v-col>
    </v-row>
    <WebsocketData :url-input="[websocketURL]" :require-user-send="[true]" :data.sync="websocketData" tag="span">
    </WebsocketData>
    <ConfirmDialog ref="confirmDialogRef"/>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import WebsocketData from "@/components/WebsocketData";
import ConfirmDialog from "@/components/ConfirmDialog";
import colors from 'vuetify/lib/util/colors'

export default {
  name: "Account",
  components: {
    WebsocketData,
    ConfirmDialog,
  },
  created: function() {
    this.getTokens()
    this.simbriefUsername = this.currentUser['simbriefUser']
  },
  data: function(){
    return {
      tokens: [],
      headers: [
        {
          text: 'Token',
          value: 'token'
        },
        {
          text: 'Time created',
          value: 'time_created',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right'
        }
      ],
      simbriefUsername: null,
      simbriefUsernameSaving: false,

      currentPassword: '',
      newPassword: '',
      updatePasswordSaving: false,

      alertClass: '',
      showAlert: false,
      alertText: '',

      websocketData: {},
      websocketURL: "web?hide_last=true"
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    clientData: function(){
      if (this.websocketData == null || Object.keys(this.websocketData).length === 0){
        return null
      }

      return this.websocketData[this.websocketURL]
    }
  },
  methods: {
    getTokens: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+ "/" + this.currentUser['user'] +"/getTokens", requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.tokens = data
            })
          }
          console.error(response.status)
          return null
        })
    },
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    deleteTokenWithPopup: function(token){
      this.$refs.confirmDialogRef.run().then(function(confirmRes){
        if (confirmRes){
          this.deleteToken(token)
        }
      }.bind(this))
    },
    deleteToken: function (token){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/deleteToken?token="+token, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.tokens = data
            })
          }
          console.error(response.status)
          return null
        })
    },
    generateToken: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/generateToken", requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.tokens = data['remaining_tokens']
            })
          }
          console.error(response.status)
          return null
        })
    },
    updateAccountSettings: function (simbriefUser){
      this.simbriefUsernameSaving = true
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/updateAccountSettings?simbrief_user="+simbriefUser, requestOptions)
        .then(response => {
          if (response.ok){
            const user = this.currentUser
            user['simbriefUser'] = this.simbriefUsername
            this.$store.dispatch('loginUser', user)
          }else{
            console.error("an error occurred updating account settings")
          }
          this.simbriefUsernameSaving = false
        })
    },
    updatePassword: function(currentPassword, newPassword){
      this.updatePasswordSaving = true
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
        body: JSON.stringify({"current_password": currentPassword, "new_password": newPassword})
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/changePassword", requestOptions)
        .then(response => {
          if (response.ok){
           this.postUpdate(true)
          }else{
            response.json().then(data => {
              this.postUpdate(false, data)
              console.error("an error occurred updating password")
            })
          }
          this.updatePasswordSaving = false
        })
    },
    postUpdate: function(success, text){
      if (success){
        this.alertClass = 'green'
        this.alertText = 'Password updated successfully'
        this.showAlert = true
        setTimeout(function(){this.showAlert = false}.bind(this), 3000)
        this.currentPassword = ''
        this.newPassword = ''
      }else{
        this.alertClass = 'red'
        this.alertText = text
        this.showAlert = true
      }
    }
  }
}
</script>

<style scoped>

</style>
